(function($) {
  // PHF 20150508 - i226273: if only one promo, no slide.
  var fsJson;
  fsJson = {
    animation: "slide",
    slideshow: false,
    pauseOnAction: false, // help with swipe
    keyboard: false,
    useCSS: false // fixes swipe in ios6+
  };

  if ( ( $('.responsive-carousel .flexslider .slides li').length - $('.responsive-carousel .flexslider .slides li.slide.clone').length ) == 1 ) {
    fsJson.touch = false;    
  }

  Drupal.behaviors.carousel_responsive = {
    attach: function(context, settings) {
      $('.responsive-carousel .flexslider').flexslider( fsJson );
    }
  };
})(jQuery);
